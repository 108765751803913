<script lang="ts" setup>
const props = defineProps<{
    modelValue?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const {
    openModal, isOpenOnCurrentModal, closeModal,
} = useModal()

watch(() => props.modelValue, (newVal, oldVal) => {
    if (newVal !== oldVal && newVal) {
        openModal()
    }
})

watch(isOpenOnCurrentModal, (newVal, oldVal) => {
    if (newVal !== oldVal && !newVal) {
        emit('update:modelValue', false)
        closeModal()
    }
})
</script>

<template>
    <Teleport v-if="isOpenOnCurrentModal" to="#modal-content">
        <slot />
    </Teleport>
</template>
