<script lang="ts" setup>
import { useStock } from '~~/composables/api'
const props = defineProps<{
    modelValue?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const { success } = useNotification()

const { closeSidebar } = useSidebar()
const { closeModal } = useModal()
const { clearStock } = useStock()

const internalValue = ref(false)

watch(() => props.modelValue, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        internalValue.value = newVal
    }
})

watch(internalValue, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        emit('update:modelValue', newVal)
    }
})

const onClear = async () => {
    await clearStock()
    success('Estoque limpo com sucesso!')
    closeModal()
    closeSidebar()
}

const onCancel = async () => {
    closeModal()
}
</script>

<template>
    <modal v-model="internalValue">
        <div>
            <p text-9 text-textcolor-100 flex items-center justify-center mt-6>
                Limpar Estoque
            </p>
            <div flex items-center justify-center mt-6 gap-10>
                <cc-button mt-4 @click="onClear">
                    <template #icon>
                        <div i-material-symbols:delete-forever />
                    </template>
                    Limpar
                </cc-button>

                <cc-button secondary mt-4 @click="onCancel">
                    <template #icon>
                        <div i-material-symbols:cancel-outline />
                    </template>
                    Cancelar
                </cc-button>
            </div>
        </div>
    </modal>
</template>
