<script lang="ts" setup>
import { useStock, useUser, useWarehouse } from '~~/composables/api'

const { isLocked, scrollByY } = useScroll()
const page = ref<HTMLElement | null>(null)
const isPageScrollLocked = useScrollLock(page)
const { isSplashScreenVisible, isOnLoginFlow } = useSplashScreen()
const user = useSupabaseUser()
const { isNavbarVisible } = useNavbar()
const { stockEntryListDisplaySize } = useStock()
const { currentJobType } = useJobTypes()
const { watchNetwork } = useOffline()
const { userInfo, getUserByIdAuth } = useUser()
const { isReceivementSelectionModalVisible, isReceivementToStockSelectionModalVisible } = useJobTypes()

watchEffect(async () => {
    if (user.value) {
        useRole()
        if (userInfo.value === null) {
            await getUserByIdAuth()
        }
    }
})
useWarehouse()

watchNetwork()
watch([scrollByY], ([offsetY]) => {
    console.log(offsetY)
    page.value.scrollBy({
        top: (offsetY - 80) - page.value.scrollTop,
        behavior: 'smooth',
    })
})

const isLogged = computed(() => user.value)

watchEffect(() => {
    isPageScrollLocked.value = isLocked.value
})

watchEffect(() => {
    if (user.value) {
        setTimeout(() => {
            isOnLoginFlow.value = false
        }, 700)
    }
})

useInfiniteScroll(
    page,
    () => {
        stockEntryListDisplaySize.value += 10
    },
    { distance: 30 },
)
</script>

<template>
    <main>
        <app-splash-screen
            v-if="isSplashScreenVisible || isOnLoginFlow"
            class="text-center container-width"
            overflow-scroll
            overflow-x-hidden
            h-screen
            m-auto
            font-sans
        />
        <div
            v-show="!isSplashScreenVisible"
            ref="page"
            class="text-center container-width"
            m-auto
            font-sans
            bg-backgroundlight relative
            overflow-scroll
            overflow-x-hidden
            h-screen flex flex-col text-left
        >
            <app-navbar
                v-show="isLogged"
                fixed class="z-1 --limit-width" transition-all
                :class="[
                    isNavbarVisible ? 'bottom-0' : 'bottom--50',
                ]"
            />
            <app-header v-show="isLogged" class="sticky top-0 z-1" />
            <app-sidebar />
            <div grow>
                <slot />
            </div>
            <app-receivement-select-modal
                v-model="isReceivementSelectionModalVisible"
                :selectedJobType="currentJobType" />
            <app-modal-container />
            <app-receivement-to-stock-select-modal
                v-model="isReceivementToStockSelectionModalVisible"
                :selectedJobType="currentJobType"
            />
        </div>
    </main>
</template>

<style scoped>
.container-width {
    max-width: var(--app-max-width);
}

.--limit-width {
    --app-margin: max(0px, calc(((100vw - var(--app-max-width)) / 2)));
    left: var(--app-margin);
    right: var(--app-margin);
}
</style>
