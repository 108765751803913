<script lang="ts" setup>
import type { SelectItem } from '~~/types/select'
import type { JobType } from '~~/types/job-type'
import { jobTypeMappings } from '~~/types/job-type'

const props = defineProps<{
    modelValue?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const { listJobTypes, currentJobType } = useJobTypes()
const { closeSidebar } = useSidebar()
const { closeModal } = useModal()
const selectedJobType = ref<SelectItem | null>(null)
const internalValue = ref(false)
const contactCompanySelectEnabled = ref<Boolean>(false)

const makeJobSelectItem = (item: JobType) => {
    return {
        label: jobTypeMappings(item?.name) || 'nao definido',
        value: item.name,
        metadata: item,
    }
}
const jobsList: SelectItem<JobType>[] = listJobTypes().map(
    (item: JobType) => makeJobSelectItem(item),
)

onMounted(() => {
    const thisJob = currentJobType.value ? makeJobSelectItem(currentJobType.value) : jobsList[0]
    selectedJobType.value = thisJob
})

onUnmounted(() => {
    emit('update:modelValue', false)
})

watch(() => props.modelValue, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        internalValue.value = newVal || false
    }
})

watch(internalValue, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        emit('update:modelValue', newVal)
    }
})

const onSelected = (item: SelectItem<JobType>) => {
    currentJobType.value = item?.metadata || null
    if (item.label === 'receber') {
        contactCompanySelectEnabled.value = item.label === 'receber'
    }
    closeModal()
    closeSidebar()
}
</script>

<template>
    <modal v-model="internalValue">
        <div>
            <cc-select
                v-model="selectedJobType"
                :items="jobsList"
                placeholder="Selecione uma tarefa."
                label="Tarefa"
                @selected="onSelected"
            />
        </div>

        <div text-16 text-textcolor-100 flex items-center justify-center mt-6>
            Tarefa
        </div>
    </modal>
</template>
