<script lang="ts" setup>
import { useMotions } from '@vueuse/motion'

const { hideSplashScreen } = useSplashScreen()
const { searchTerm, sortBy, toggleSortBy } = useSearch()
const motions = useMotions()

const isSearchBarVisible = ref(false)
const searchBar = ref<HTMLElement | null>(null)

const toggleSearchBar = () => {
    isSearchBarVisible.value = !isSearchBarVisible.value
}
onMounted(() => {
    hideSplashScreen()
})

onClickOutside(searchBar, () => {
    isSearchBarVisible.value = false
})
</script>

<template>
    <div
        ref="searchBar"
    >
        <div
            bg-transparent
            px-5 py-4
            bg-gradient-to-t via-white from-white to-transparent
            flex flex-col-reverse
            transition-all backdrop-blur-1px
            :class="[
                isSearchBarVisible ? '!h-290px' : '!h-20',
            ]"
        >
            <div
                flex justify-between items-center
                px-10 mt-4
            >
                <cc-button ghost disabled aspect-ratio-1 rounded-full class="!p-2 !bg-backgroundlight/50 !hover:bg-backgroundlight/50 !cursor-not-allowed !backdrop-blur-10px">
                    <template #icon>
                        <div i-material-symbols-filter-list relative left-2px />
                    </template>
                </cc-button>
                <cc-button
                    rounded-full ghost class="!p-3 px-2 shadow-lg !bg-backgroundlight/50 !hover:bg-primary-300 !backdrop-blur-10px " aspect-ratio-1
                    :class="[
                        isSearchBarVisible ? '!bg-primary-300 !text-white pulsing' : '',
                    ]"
                    @click="toggleSearchBar"
                >
                    <template #icon>
                        <div i-material-symbols-search text-8 />
                    </template>
                </cc-button>
                <cc-button
                    ghost
                    aspect-ratio-1 rounded-full class="!p-2 !bg-backgroundlight/50 !hover:bg-primary-300 !backdrop-blur-10px"
                    @click="toggleSortBy"
                >
                    <template #icon>
                        <div v-if="sortBy === 'asc'" class="i-mdi-sort-alphabetical-ascending-variant relative left-2px" />
                        <div v-if="sortBy === 'desc'" class="i-mdi-sort-alphabetical-descending-variant relative left-2px" />
                    </template>
                </cc-button>
            </div>
        </div>
        <transition
            :css="false"
            @leave="(_, done) => motions.searchBar.leave(done)"
        >
            <div
                v-if="isSearchBarVisible"
                v-motion="'searchBar'"
                :initial="{ opacity: 0, top: 100 }"
                :enter="{ opacity: 1, top: 120, transitionDuration: '50ms' }"
                :leave="{ opacity: 0, top: 100 }" absolute left-4
                right-4
            >
                <cc-input
                    ref="searchInput"
                    v-model="searchTerm"
                    label="Busque por um produto"
                />
            </div>
        </transition>
    </div>
</template>

<style>
.pulsing {
    animation: shadow-pulse 1.5s infinite ease;
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0px 0 #187b2b55;
    }
    70% {
        box-shadow: 0 0 20px 15px #187b2b00;
    }
    100% {
        box-shadow: 0 0 20px 0 #187b2b00;
    }
}
</style>
