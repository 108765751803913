<script lang="ts" setup>
const { isModalOpen, closeModal, currentModalId } = useModal()
const onOverlayClick = () => {
    if (isModalOpen.value) {
        closeModal()
    }
}
</script>

<template>
    <div>
        <div
            class="bg-#0E452FB2 --limit-width" :class="[
                { [`op100 z-999999`]: isModalOpen },
            ]"
            op-0
            fixed top-0 bottom-0 transition
            z--1
            @click="onOverlayClick"
        />
        <div
            ref="container"
            class="--limit-width --limit-height" :class="[
                { [`op100 z-9999999`]: isModalOpen },
            ]"
            op-0
            fixed bottom-0 left-0 right-0 bg-white
            rounded-t-8 z--1 transition
        >
            <div id="modal-content" p-6 />
        </div>
    </div>
</template>

<style scoped>
.--limit-width {
    --app-margin: max(0px, calc(((100vw - var(--app-max-width)) / 2)));
    left: var(--app-margin);
    right: var(--app-margin);
}

.--limit-height {
    max-height: 75vh;
    overflow-y: auto;
}
</style>
