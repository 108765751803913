<script lang="ts" setup>
import type { SelectItem } from '~~/types/select'
import type { ContactCompany } from '~~/composables/api/brain/contact-company/types'
import { useContactCompany } from '~~/composables/api/brain/contact-company'
import { useJobTypes } from '~~/composables/job-type'
import type { JobType } from '~~/types/job-type'

import { useReceivementType } from '~~/composables/receivementType'

const props = defineProps<{
    modelValue?: boolean
    selectedJobType: JobType
}>()

const emit = defineEmits(['update:modelValue'])

const { closeSidebar } = useSidebar()
const { closeModal } = useModal()
const { currentJobType } = useJobTypes()
const { currentReceivementType, receivementTypesList } = useReceivementType()
const { currentContactCompany, contactCompanies, fetchContactCompanies } = useContactCompany()
const { resetExpandedCards } = useExpandedCardManager()

const internalValue = ref(false)
const selectedReceivementType = ref<string>(currentReceivementType.value)
const selectedContactCompany = ref<string>('')

const selectedContactCompanyModel = ref<ContactCompany | null>(null)

watchEffect(async () => {
    if (!contactCompanies.value) {
        fetchContactCompanies()
    }
})
const mapCompaniesToSelectItems = (companies: ContactCompany[] | null): SelectItem<ContactCompany>[] => {
    return (Array.isArray(companies)
        ? companies.filter((item: ContactCompany) => Boolean(item.name)).map((item: ContactCompany) => {
            return {
                label: item.name,
                value: item.id_contact_company,
                metadata: item,
            }
        })
        : [])
}

onUnmounted(() => {
    emit('update:modelValue', false)
})

watch(() => props.modelValue, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        internalValue.value = newVal || false
    }
})

watch(internalValue, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        emit('update:modelValue', newVal)
    }
})

const onContactCompaniesQuery = async (query: string, _) => {
    await fetchContactCompanies({
        page: 1,
        size: 20,
        search: query,
    })
    return mapCompaniesToSelectItems(contactCompanies.value)
}

const defaultCompanies = computed<SelectItem[]>(() => {
    if (contactCompanies.value) {
        return mapCompaniesToSelectItems(contactCompanies.value)
    }
})

const onSelected = (item: SelectItem<ContactCompany>) => {
    selectedContactCompanyModel.value = item?.metadata || null
}

const showDisplayCompany = computed(() => {
    return ['devolution', 'waste_faas'].includes(selectedReceivementType.value)
})

const onSave = () => {
    resetExpandedCards()
    currentJobType.value = props.selectedJobType
    currentReceivementType.value = selectedReceivementType.value
    currentContactCompany.value = selectedContactCompanyModel.value
    selectedContactCompany.value = ''
    closeModal()
    emit('update:modelValue', false)
    closeSidebar()
}
</script>

<template>
    <modal v-model="internalValue">
        <div>
            <cc-select
                v-model="selectedReceivementType"
                :items="Object.values(receivementTypesList)"
                placeholder="Selecionar Tipo de Recebimento"
                label="Tipo de Recebimento"
                disableInput="true"
            />
        </div>
        <div>
            <cc-select
                v-if="showDisplayCompany"
                v-model="selectedContactCompany"
                :items="defaultCompanies"
                :on-query="onContactCompaniesQuery"
                placeholder="Selecionar Cliente"
                label="Clientes"
                @selected="onSelected"
            />
        </div>
        <cc-button mt-4 :disabled="!selectedContactCompany" @click="onSave">
            <template #icon>
                <div i-material-symbols-save />
            </template>
            Salvar Recebimento
        </cc-button>
    </modal>
</template>
