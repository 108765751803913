export interface JobType {
    name: string
    reference: string
}

export const jobTypeMappings = (name: string) => {
    return {
        stockCount: 'Contagem De Estoque',
        receivement: 'Recebimento',
        receivementToStock: 'Triagem Para Estoque',
    }[name]
}
