<script lang="ts" setup>
import { useStock, useWarehouse } from '~~/composables/api'
import { jobTypeMappings } from '~~/types/job-type'
import { useContactCompany } from '~~/composables/api/brain/contact-company'

const user = useSupabaseUser()
const { currentContactCompany } = useContactCompany()
const { currentWarehouse } = useWarehouse()
const { currentJobType, isReceivementSelectionModalVisible, isReceivementToStockSelectionModalVisible } = useJobTypes()
const { isOffline } = useOffline()
const { checkExpandedCards } = useExpandedCardManager()
const { getAllEntries } = useStock()

const name = computed(() => user.value?.user_metadata?.name)
const avatar = computed(() => user.value?.user_metadata?.picture)

const { openSidebar } = useSidebar()

const clickSidebarButton = (): void => {
    checkExpandedCards(getAllEntries())
    openSidebar()
}

const clickJobTypeComponent = (): void => {
    checkExpandedCards(getAllEntries())
    const jobButtonFunction: { [key: string]: () => void } = {
        receivement: () => { isReceivementSelectionModalVisible.value = true },
        receivementToStock: () => { isReceivementToStockSelectionModalVisible.value = true },
    }

    const buttonFunction = (jobButtonFunction[currentJobType.value?.name] || openSidebar)
    buttonFunction()
}
</script>

<template>
    <div
        px-5 pt-4 pb-3
        flex items-center flex-row-reverse
        transition-all backdrop-blur-8px
        :class="[
            isOffline
                ? '!bg-secondary !text-white shadow-secondary-700/15'
                : 'bg-gradient-to-b to-white/60 from-white text-textcolor-300 shadow-primary-700/15',
            currentJobType?.name === 'stockCount' ? 'bg-transparent' : '',
            currentJobType?.name === 'receivement' ? 'bg-primary' : '',
            currentJobType?.name === 'receivementToStock' ? 'bg-secondary' : '',
        ]"
        class="" shadow-lg
    >
        <nuxt-img v-if="avatar" referrerpolicy="no-referrer" :src="avatar" rounded-full w-8 h-8 />
        <div v-if="name" text-14px font-bold mr-2>
            <div>
                {{ name }}
            </div>
            <div v-if="isOffline" class="bg-dark/10 justify-center items-center " p-1 rounded flex gap-1 px-4>
                <div i-material-symbols:portable-wifi-off-rounded />
                Offline
            </div>
        </div>

        <div
            flex items-center mr-auto class="hover:bg-primary-100/60"
            cursor-pointer transition px-2 py-1 rounded-2
            border-2 border-primary-300
            @click="clickJobTypeComponent"
        >
            <div i-material-symbols-menu text-textcolor-300 mr-2 />
            <div flex flex-col text-center max-w-80px>
                <div text-12px>
                    {{ jobTypeMappings(currentJobType?.name) || 'Selecionar Tarefa' }}
                </div>
                <div v-if="Boolean(currentContactCompany)" text-9px p-1 bg-backgroundlight rounded-2 border-1 border-primary-100 >
                    {{ currentContactCompany?.name || 'sem cliente selecionado' }}
                </div>
            </div>
        </div>

        <div
            v-if="currentWarehouse"
            flex items-center mr-auto class="hover:bg-primary-100/60"
            cursor-pointer transition px-2 py-1 rounded-2
            @click="clickSidebarButton"
        >
            <div i-material-symbols-warehouse-outline-rounded mr-2 />
            <div text-12px>
                {{ currentWarehouse.name }}
            </div>
        </div>
        <cc-skeleton v-else w-25 h-5 rounded-4 mr-auto />
    </div>
</template>
