<script lang="ts" setup>
import { useStock, useUser } from '~~/composables/api'
import { useJobTypes } from '~~/composables/job-type'
import { useContactCompany } from '~~/composables/api/brain/contact-company'

const { warning } = useNotification()
const { closeSidebar } = useSidebar()
const { userInfo } = useUser()
const { hasRole } = useRole()
const user = useSupabaseUser()
const { currentJobType, listJobTypes, isReceivementSelectionModalVisible, isReceivementToStockSelectionModalVisible } = useJobTypes()
const { currentContactCompany } = useContactCompany()
const { resetExpandedCards } = useExpandedCardManager()
const config = useRuntimeConfig()

const userName = computed(() => user.value?.user_metadata?.name || user.value?.email)
const isWarehouseSelectionModalVisible = ref(false)
const isJobTypeSelectionModalVisible = ref(false)
const isClearStockModalVisible = ref(false)
const selectedJobType = ref(null)

const itemList = [
    {
        title: 'Mudar depósito',
        visible: true,
        icon: 'i-material-symbols-add-business-rounded',
        onClick: () => {
            if (userInfo?.value?.warehouse?.id_warehouse) {
                closeSidebar()
                warning('Você esta vinculado a um único depósito.')
            }
            else {
                isWarehouseSelectionModalVisible.value = true
            }
        },
        dataTestId: 'change-warehouse-link',
    },
    {
        title: 'Receber',
        visible: true,
        icon: 'i-material-symbols-local-shipping-rounded',
        onClick: () => {
            // this modal is over in layouts/default.vue
            // it has to be more public bc it is used in the header
            currentJobType.value = listJobTypes().find(i => i.name === 'receivement')
            isReceivementSelectionModalVisible.value = true
        },
        dataTestId: 'change-contact-company-link',
    },
    {
        title: 'Triagem Para Estoque',
        visible: true,
        icon: 'i-material-symbols-inventory-2-rounded',
        onClick: () => {
            // this modal is placed over in layouts/default.vue
            // it has to be more public bc it is used in the header
            currentJobType.value = listJobTypes().find(i => i.name === 'receivementToStock')
            isReceivementToStockSelectionModalVisible.value = true
        },
        dataTestId: 'change-contact-company-link',
    },
    {
        title: 'Contar Estoque',
        visible: true,
        icon: 'i-material-symbols-swap-horiz-rounded',
        onClick: () => {
            resetExpandedCards()
            currentJobType.value = listJobTypes().find(({ name }) => name === 'stockCount')
            currentContactCompany.value = null
            closeSidebar()
        },
        dataTestId: 'change-job-type-link',
    },
    {
        title: 'Limpar estoque do depósito',
        visible: true,
        icon: 'i-material-symbols-delete',
        onClick: async () => {
            if (hasRole('clicampo:estocaqui:admin')) {
                isClearStockModalVisible.value = true
            }
            else {
                closeSidebar()
                warning('Você não tem permissão para limpar o estoque.')
            }
        },
        dataTestId: 'empty-stock-link',
    },
    {
        title: 'Sair',
        visible: true,
        icon: 'i-material-symbols-logout',
        onClick: () => {
            closeSidebar()
            navigateTo('/logout', { clearHistory: true })
        },
        dataTestId: 'logout',
    },
] as {
    title: string
    icon: string
    visible: boolean
    onClick: () => void
    dataTestId?: string
    to?: string
}[]

const handleClick = (onClick) => {
    onClick()
}
</script>

<template>
    <app-sidebar-container>
        <div py-4>
            <div v-if="user" flex items-center flex-col>
                <div
                    v-if="userName" w-16 h-16 bg-primary-300 rounded-full text-white font-900 text-8 flex items-center
                    justify-center b-4 b-primary-100
                >
                    {{ userName[0] }}
                </div>
                <div
                    mt-2 text-textcolor font-800 :class="[
                        userName.length > 20 ? 'text-sm' : '',
                    ]"
                >
                    {{ userName }}
                </div>
            </div>
            <div>
                <div w-240px h-2px bg-backgroundlight my-5 mx-auto />
                <nuxt-link
                    v-for="item in itemList" v-show="item.visible" :key="item.title" :to="item.to" flex items-center
                    text-primary hover:bg-backgroundlight px-4 py-2 transition :data-test-id="item.dataTestId"
                    @click="() => handleClick(item.onClick)"
                >
                    <div :class="item.icon" mr-3 />
                    <div font-800>
                        {{ item.title }}
                    </div>
                    <div i-material-symbols-chevron-right ml-auto />
                </nuxt-link>
            </div>
            <div mt-12>
                <div font-bold text-textcolor-300 text-center my-1 text-14px>
                    Versão do aplicativo
                </div>
                <div text-12px text-textcolor-100 max-w-120px mx-auto break-words text-center>
                    {{ config.public.appVersion }}
                </div>
            </div>
            <app-warehouse-select-modal v-model="isWarehouseSelectionModalVisible" />
            <app-job-type-select-modal v-model="isJobTypeSelectionModalVisible" />
            <app-clear-stock-modal v-model="isClearStockModalVisible" />
        </div>
    </app-sidebar-container>
</template>
