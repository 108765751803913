<script lang="ts" setup>
import type { Warehouse } from '~~/composables/api/brain/warehouse'
import { useUser, useWarehouse } from '~~/composables/api'

import type { SelectItem } from '~~/types/select'
const props = defineProps<{
    modelValue?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const { closeSidebar } = useSidebar()
const { closeModal } = useModal()
const { listWarehouses, currentWarehouse } = useWarehouse()
const { userInfo } = useUser()
const warehouseList = ref<SelectItem<Warehouse>[] | null>(null)
const selectedWarehouse = ref<string>(null)
const internalValue = ref(false)

onMounted(() => {
    selectedWarehouse.value = currentWarehouse.value?.id_warehouse
})

watchEffect(async () => {
    if (warehouseList.value === null) {
        warehouseList.value = (await listWarehouses()).map(warehouse => ({
            label: warehouse.name,
            value: warehouse.id_warehouse,
            metadata: warehouse,
            id_location: warehouse.id_location,
        })).filter(warehouse => !userInfo.value?.location?.id_location || warehouse?.id_location === userInfo.value?.location?.id_location)
    }
})

watch(() => props.modelValue, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        internalValue.value = newVal
    }
})

watch(internalValue, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        emit('update:modelValue', newVal)
    }
})

const onSelected = (item: SelectItem<Warehouse>) => {
    currentWarehouse.value = item.metadata
    closeModal()
    closeSidebar()
}
</script>

<template>
    <modal v-model="internalValue">
        <div>
            <cc-select
                v-model="selectedWarehouse" :items="warehouseList" placeholder="Selecione um depósito"
                label="Depósito" @selected="onSelected"
            />

            <div text-16 text-textcolor-100 flex items-center justify-center mt-6>
                <div i-material-symbols-warehouse-outline-rounded />
            </div>
        </div>
    </modal>
</template>
